import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

import {ClearCacheProvider} from 'react-clear-cache';
import store from './redux/index'
import {Provider} from 'react-redux'


//GET BORNE ID
window.ISWEB = !window.ReactNativeWebView

const searchParams = new URLSearchParams(window.location.search)
//console.log(searchParams)
if (searchParams.get("borne")) {
    window.BORNEID = searchParams.get("borne")
}

if (!window.BORNEID && localStorage.getItem("borne")) {
    window.BORNEID = localStorage.getItem("borne")

} else if (window.BORNEID) {
    localStorage.setItem("borne", window.BORNEID)
}

if (!window.BORNEID)
{
    //TEST MODE

    const root = ReactDOM.createRoot(document.getElementById('root'));
    root.render(
        <div>
            Borne loaded
        </div>
    );

}else
{
    const root = ReactDOM.createRoot(document.getElementById('root'));
    root.render(
        <ClearCacheProvider duration={5000}>
            <Provider store={store}>
                <App/>
            </Provider>
        </ClearCacheProvider>
    );
}

